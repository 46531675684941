<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </div>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(title)="data">
          <button
            type="button"
            @click="toCopy(data.item.url)"
            class="btn btn-link"
            style="padding: 0px !important"
          >
            <feather-icon class="mr-50" icon="CopyIcon" size="13" />
            {{ data.item.title }}
        </button>
        </template>

        <template #cell(classes)="data">
          <b-badge variant="light-dark" class="text-capitalize">
            {{ data.item.classes }}
          </b-badge>
        </template>

        <template #cell(media_type_name)="data">
          <b-badge variant="light-dark" class="text-capitalize">
            {{ data.item.media_type_name }}
          </b-badge>
        </template>

        <b-badge variant="light-dark"></b-badge>

        <template #cell(extension)="data">
          <i
            class="bi"
            style="font-size: 1.4rem"
            :class="`bi-filetype-${data.item.extension.toLowerCase()}`"
          ></i>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="detailsMaterial(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="">
              Foram encontrados <strong>{{ totalRows }}</strong> resultados
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-create-media"
      modal-class="modal-create"
      title="Upload de arquivo"
      centered
      hide-footer
    >
      <b-card-text>
        <form @submit.prevent="submitUpload" enctype="multipart/form-data">
          <div class="form-row">
            <div class="col-12">
              <div class="form-group">
                <label for="">
                  Título
                  <i class="text-danger bi bi-record-circle"></i>
                </label>
                <input
                  require
                  type="text"
                  :class="{ 'is-invalid': $v.item.title.$error }"
                  v-model="item.title"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label for="">
                  Arquivo
                  <i class="text-danger bi bi-record-circle"></i>
                </label>
                <input
                  style="padding: 0.4rem 0.5rem"
                  type="file"
                  id="file"
                  ref="file"
                  class="form-control"
                  @change="handleFileUpload()"
                  :class="{ 'is-invalid': $v.item.file.$error }"
                />
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-6 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-media')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                text="Cadastrar"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-details-media"
      modal-class="modal-create"
      title="Detalhes do material"
      centered
      hide-footer
    >
      <div class="form-row">
        <div class="col-12">
          <div class="form-group">
            <label for="">Título</label>
            <a :href="details.url" target="_blank" class="d-flex-left">
              {{ details.title }}
            </a>
          </div>
          <div class="form-group mt-2">
            <label for="" class="mb-75">Informações adicionais:</label>
            <strong>Size</strong>: {{ details.size }}kb - <strong>Host</strong>:
            {{ details.driver }} - <strong>Extensão</strong>:
            {{ details.extension }}
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BImgLazy,
  BCard,
  BCardText,
  BBadge,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BPagination,
  BTooltip,
  BFormRow,
  BSidebar,
  BFormFile,
  BSpinner,
  BModal,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import { required } from "vuelidate/lib/validators";
import FileIcon from "@/views/components/icon-file/Icon";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import { useClipboard } from '@vueuse/core'

export default {
  directives: {
    Ripple,
  },
  components: {
    ButtonsActionsFooter,
    BTabs,
    BCardText,
    BImgLazy,
    BTab,
    BSidebar,
    BAvatar,
    BModal,
    BSpinner,
    BFormFile,
    BForm,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    FileIcon,
  },
  validations: {
    item: {
      title: {
        required,
      },
      file: {
        required,
      },
    },
  },
  data() {
    return {
      details: {},
      item: {
        title: "",
        file: null,
        media_type_id: 8,
        teacher: [],
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
      },
      submited: false,
      submitedFilter: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      dataItems: [],
      tableColumns: [
        {
          key: "title",
          label: "Título",
        },
        {
          key: "extension",
          label: "Extensão",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "media_type_name",
          label: "Categoria",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      optionsTeacher: [],
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
      filters: {
        tag_subject: [],
        tag_contest: [],
        tag_timeline: [],
        rangeDate: null,
        title: "",
        type: 8,
      },
    };
  },
  computed: {
    ...mapState("Media", ["medias", "media", "loading"]),
    ...mapState("Teacher", ["teachers", "searchTeachers"]),
    ...mapState("Tag", [
      "searchTagsTema",
      "searchTagsConcurso",
      "searchTagsLinhadoTempo",
    ]),
  },
  methods: {
    toCopy(text) {
      const { copy } = useClipboard()
      copy(text)
      this.notifyDefault("success", "Link copiado com sucesso!");
    },
    clearForm() {
      this.item = {
        title: "",
        file: null,
        media_type_id: 8,
        teacher: [],
        tags: {
          subject: [],
          contest: [],
          timeline: [],
        },
      };
    },
    hideModal() {
      this.$refs["media-upload"].hide();
    },
    submitFilter() {
      this.getData();
    },
    detailsMaterial(uuid) {
      this.$store.dispatch("Media/show", uuid).then((res) => {
        this.$bvModal.show("modal-details-media");
        this.details = {
          title: res.title,
          url: res.url,
          extension: res.extension,
          teacher: res.teacher,
          driver: res.driver,
          size: res.size,
          tags: {
            subject: res.tags.subject,
            contest: res.tags.contest,
            timeline: res.tags.timeline,
          },
        };
      });
    },
    handleFileUpload() {
      this.item.file = this.$refs.file.files[0];
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name.replaceAll(" ", "-").toLowerCase();
      }
      return `${files.length} arquivos selecionados`;
    },
    submitUpload() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("file", this.item.file);
      formData.append("title", this.item.title);
      formData.append("media_type_id", 8);

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Media/create", formData)
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
            this.getData();
            this.$bvModal.hide("modal-create-media");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        type: this.filters.type ?? "",
        title: this.filters.title ?? "",
      };

      this.$store
        .dispatch("Media/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: filtersSearch,
        })
        .then(() => {
          if (this.medias) {
            this.dataItems = this.medias.data;
            this.rowsTable = this.medias.data.length;
            this.totalRows = this.medias.meta.total;
            this.currentPage = this.medias.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o material caso não tenha nenhum vinculo com outros registros.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("Media/delete", { uuid: uuid })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
            })
            .catch(() => {
              this.notifyDefault("error");
            });
        }
      });
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-media", () => {
      this.clearForm();
      this.$bvModal.show("modal-create-media");
    });
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-media");
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
