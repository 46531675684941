var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-4" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "search", placeholder: "Pesquisar..." },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.filter = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c(
            "b-table",
            {
              staticClass: "position-relative",
              attrs: {
                "sticky-header": "",
                items: _vm.dataItems,
                responsive: "",
                fields: _vm.tableColumns,
                "show-empty": "",
                "empty-text": "Nenhum registro encontrado",
                filter: _vm.filter,
                "filter-included-fields": _vm.filterOn,
              },
              on: { filtered: _vm.onFiltered },
              scopedSlots: _vm._u([
                {
                  key: "cell(title)",
                  fn: function (data) {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          staticStyle: { padding: "0px !important" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toCopy(data.item.url)
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "CopyIcon", size: "13" },
                          }),
                          _vm._v(" " + _vm._s(data.item.title) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(classes)",
                  fn: function (data) {
                    return [
                      _c(
                        "b-badge",
                        {
                          staticClass: "text-capitalize",
                          attrs: { variant: "light-dark" },
                        },
                        [_vm._v(" " + _vm._s(data.item.classes) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(media_type_name)",
                  fn: function (data) {
                    return [
                      _c(
                        "b-badge",
                        {
                          staticClass: "text-capitalize",
                          attrs: { variant: "light-dark" },
                        },
                        [_vm._v(" " + _vm._s(data.item.media_type_name) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "cell(extension)",
                  fn: function (data) {
                    return [
                      _c("i", {
                        staticClass: "bi",
                        class:
                          "bi-filetype-" + data.item.extension.toLowerCase(),
                        staticStyle: { "font-size": "1.4rem" },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex-between" },
                        [
                          _c("feather-icon", {
                            staticClass: "cursor-pointer cursor text-danger",
                            attrs: { icon: "TrashIcon", size: "16" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmDelete(data.item.uuid)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            staticClass: "cursor-pointer cursor",
                            attrs: { icon: "EyeIcon", size: "16" },
                            on: {
                              click: function ($event) {
                                return _vm.detailsMaterial(data.item.uuid)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("b-badge", { attrs: { variant: "light-dark" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("span", {}, [
                        _vm._v(" Foram encontrados "),
                        _c("strong", [_vm._v(_vm._s(_vm.totalRows))]),
                        _vm._v(" resultados "),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "my-1",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.perPage,
                          limit: 10,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.getData()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronLeftIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "next-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-create-media",
            "modal-class": "modal-create",
            title: "Upload de arquivo",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("b-card-text", [
            _c(
              "form",
              {
                attrs: { enctype: "multipart/form-data" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitUpload.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Título "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.title,
                            expression: "item.title",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.item.title.$error },
                        attrs: { require: "", type: "text" },
                        domProps: { value: _vm.item.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.item, "title", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Arquivo "),
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                      ]),
                      _c("input", {
                        ref: "file",
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.item.file.$error },
                        staticStyle: { padding: "0.4rem 0.5rem" },
                        attrs: { type: "file", id: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.handleFileUpload()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-row justify-content-end modal-footer-custom",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light float-left",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.$bvModal.hide("modal-create-media")
                              },
                            },
                          },
                          [_vm._v(" Fechar ")]
                        ),
                        _c("ButtonsActionsFooter", {
                          attrs: {
                            variant: "success",
                            submited: _vm.submited,
                            text: "Cadastrar",
                            subtext: "Aguarde...",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-details-media",
            "modal-class": "modal-create",
            title: "Detalhes do material",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "" } }, [_vm._v("Título")]),
                _c(
                  "a",
                  {
                    staticClass: "d-flex-left",
                    attrs: { href: _vm.details.url, target: "_blank" },
                  },
                  [_vm._v(" " + _vm._s(_vm.details.title) + " ")]
                ),
              ]),
              _c("div", { staticClass: "form-group mt-2" }, [
                _c("label", { staticClass: "mb-75", attrs: { for: "" } }, [
                  _vm._v("Informações adicionais:"),
                ]),
                _c("strong", [_vm._v("Size")]),
                _vm._v(": " + _vm._s(_vm.details.size) + "kb - "),
                _c("strong", [_vm._v("Host")]),
                _vm._v(": " + _vm._s(_vm.details.driver) + " - "),
                _c("strong", [_vm._v("Extensão")]),
                _vm._v(": " + _vm._s(_vm.details.extension) + " "),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }